var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { ref: "searchbox", on: { enter: _vm.getList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    required: true,
                    comboItems: _vm.searchCndItems,
                    type: "none",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "searchCnd",
                    label: "검색구분",
                  },
                  model: {
                    value: _vm.searchParam.searchCnd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "searchCnd", $$v)
                    },
                    expression: "searchParam.searchCnd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6",
              },
              [
                _c("c-text", {
                  attrs: {
                    required: true,
                    label: "검색어",
                    name: "searchNm",
                    placeholder:
                      _vm.searchParam.searchCnd == "0"
                        ? "화학물질명"
                        : _vm.searchParam.searchCnd == "1"
                        ? "CAS NO"
                        : "KE NO",
                  },
                  model: {
                    value: _vm.searchParam.searchNm,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "searchNm", $$v)
                    },
                    expression: "searchParam.searchNm",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "materialTable",
          attrs: {
            title: "화학물질 목록",
            columns: _vm.grid.columns,
            data: _vm.grid.data,
            gridHeight: _vm.grid.height,
            selection: _vm.popupParam.type,
            rowKey: "chemId",
          },
          on: { rowDblclick: _vm.rowDblclick },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-btn", {
                    attrs: { label: "LBLSEARCH", icon: "search" },
                    on: { btnClicked: _vm.getList },
                  }),
                  _c("c-btn", {
                    attrs: { label: "LBLSELECT", icon: "check" },
                    on: { btnClicked: _vm.select },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }